import { useState } from 'react';
import useCountDown from 'react-countdown-hook';
import styled from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import {
  Link,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { confirmAlert } from 'react-confirm-alert';
import CloseMenu from '../../assets/icons/closeMenu.svg';
import IconMenu from '../../assets/icons/iconMenu.svg';
import logo from '../../assets/icons/navicons/Big-Logo.svg';
import smalllogo from '../../assets/icons/navicons/logo.png';
import AppRoute from '../../components/AppRoute';
import IdleModal from '../../components/modals/idleModal/IdleModal';
import routes from '../../config/userRoutes';
import useUser from '../../hooks/useUser';
import NavUser from '../../layout/nav/NavUser';
import { useMobileStore } from '../../store';
import {
  StyledFlexboxGrid,
  CenterFlex,
  DisplayUserNameLetter,
  DropDownMenu,
  HamburgerLogo,
  HeaderStyled,
  ImgLogo,
  MenuButton,
  OptionsHeader,
  OptionsMobile,
  SpaceMenu,
  Title,
  Wrapper,
} from './UserPanel.styles.';
import OpenCloseIconSVG from '../../assets/icons/navicons/close.svg';
import { useAuth } from '../../context/AuthContext';
import SelectToolsDropdown from '../../components/selectToolsDropdown/SelectToolsDropdown';

import {
  AccountIcon,
  HelpIcon,

} from '../../assets';

const UserPanel = () => {
  const { user } = useAuth();
  const [isMenuClose, setIsMenuClose] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const idleTime = 15 * 60 * 1000;
  const logoutTime = 60 * 1000;
  const { handleLogout } = useUser();
  const history = useHistory();
  const { showMobileMenu, toggleMobileMenu } = useMobileStore(state => state);

  const [idle, setIdle] = useState(false);
  const [timeLeft, { start: startCountDown, reset: resetCountDown }] =
    useCountDown(logoutTime);

  if (idle && timeLeft === 0) {
    handleLogout();
    history.push('/');
  }

  const handleOnIdle = () => {
    startCountDown();
    setIdle(true);
    pause();
  };

  const handleOnAction = () => {
    resetCountDown();
    setIdle(false);
    reset();
  };

  const { pause, reset } = useIdleTimer({
    timeout: idleTime,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
  });

  const { url } = useRouteMatch();
  const location = useLocation();

  const handleCloseLeftMenu = () => {
    setIsMenuClose(!isMenuClose);
  };

  const displayName = user?.firstName
    ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
    : '';

  const displayFullName = user?.firstName
    ? `${user.firstName}  ${user.lastName}`
    : '';

  const handleRoute = () => {
    setIsOpen(!isOpen);
    // history.push(`${url}/account`);
  };

  const logoutUser = () => {
    confirmAlert({
      message: `Do you want to logout ${user?.email}?`,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => {
            handleLogout();
            history.push('/');
          },
        },
        {
          label: 'Cancel',
          onClick: () => null,
        },
      ],
    });
  };

  return (
    <>
      <IdleModal show={idle} onClose={handleOnAction} />
      <>
        <HeaderStyled align="middle">
          <CenterFlex componentClass={Col} xs={8} md={isMenuClose ? 2 : 4}>
            <ImgLogo
              isMenuClose={isMenuClose}
              src={isMenuClose ? smalllogo : logo}
              alt="dinabite logo"
            />
            <HamburgerLogo
              src={OpenCloseIconSVG}
              alt="hamburger"
              onClick={handleCloseLeftMenu}
            />
          </CenterFlex>
          <OptionsHeader componentClass={Col} xs={16} md={20}>
            <FlexboxGrid justify="space-around" align="middle">
              {location.pathname === '/user-panel/dashboard' && (
                <>
                  <FlexboxGrid.Item
                    componentClass={Col}
                    xs={22}
                    xsHidden
                    smHidden
                    style={{ padding: '0px' }}>
                    <Title>{`Hi, ${displayFullName}, welcome to Dinabite`}</Title>
                  </FlexboxGrid.Item>
                  <DisplayUserNameLetter onClick={handleRoute}>
                    {displayName}
                    {isOpen && (
                      <DropDownMenu>
                        <Link to={`${url}/account`}>
                          <Icon as={AccountIcon} />
                          <b>Account</b>
                        </Link>

                        <Link to={`${url}/help`}>
                          <Icon as={HelpIcon} />
                          <b>Feedback/Help</b>
                        </Link>
                        <Button onClick={logoutUser}>
                          {/* <Icon as={LogoutIcon} /> */}
                          <b>Logout</b>
                        </Button>
                      </DropDownMenu>
                    )}
                  </DisplayUserNameLetter>
                </>
              )}
            </FlexboxGrid>
          </OptionsHeader>
          <FlexboxGrid justify="space-around" className="tools-wrapper">
            <FlexboxGrid.Item colspan={16} lgHidden mdHidden>
              <SelectToolsDropdown hide />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2}>
              <CenterFlex componentClass={Col} xs={8} md={6} lgHidden mdHidden>
                <MenuButton
                  onClick={toggleMobileMenu}
                  icon={
                    showMobileMenu ? (
                      <img src={CloseMenu} alt="close menu" />
                    ) : (
                      <img src={IconMenu} alt="open menu" />
                    )
                  }
                  circle
                  componentClass="div"
                />
              </CenterFlex>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2}>
              <DisplayUserNameLetter onClick={handleRoute} hide>
                {displayName}
                {isOpen && (
                  <DropDownMenu>
                    <Link to={`${url}/account`}>
                      <Icon as={AccountIcon} />
                      <b>Account</b>
                    </Link>

                    <Link to={`${url}/help`}>
                      <Icon as={HelpIcon} />
                      <b>Feedback/Help</b>
                    </Link>
                    <Button onClick={logoutUser}>
                      {/* <Icon as={LogoutIcon} /> */}
                      <b>Logout</b>
                    </Button>
                  </DropDownMenu>
                )}
              </DisplayUserNameLetter>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <OptionsMobile
            display={showMobileMenu ? 1 : 0}
            componentClass={Col}
            xs={24}
            lgHidden
            mdHidden>
            {/* <FullDivider /> */}
            <NavUser />
          </OptionsMobile>
        </HeaderStyled>

        <StyledFlexboxGrid>
        <SpaceMenu
            componentClass={Col}
            md={isMenuClose ? 2 : 4}
            xsHidden
            smHidden>
            {/* <DividerSpace /> */}
            <NavUser isMenuClose={isMenuClose} />
          </SpaceMenu>
          <Wrapper componentClass={Col} xs={24} md={20} >
            {/* <DividerSpace componentClass={Col} xsHidden smHidden /> */}
            <Switch>
              {routes.map(route => (
                <AppRoute
                  key={`${url}${route.path}`}
                  path={`${url}${route.path}`}
                  component={route.component}
                  isPrivate={route.isPrivate}
                  exact={route.exact}
                />
              ))}
            </Switch>
          </Wrapper>
        </StyledFlexboxGrid>
      </>
    </>
  );
};

export default UserPanel;

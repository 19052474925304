import React from 'react';
import { useHistory } from 'react-router-dom';

import HashtagImage from '../../assets/icons/dinno.png'; // Replace with the actual path to your image
import {
  Container,
  ContentWrapper,
  TextContainer,
  Welcome,
  Description,
  Button,
  ImageContainer,
  ButtonWrapper,
} from './noconnection.style'; // Corrected import statement
import { useAuth } from '../../context/AuthContext';

const Noconnection: React.FC = () => {
    const history = useHistory();
  const { user } = useAuth(); // Get user info from auth context

  // Construct the user's full name
  const displayFullName = user?.firstName ? `${user.firstName}` : 'User'; // Fallback to 'User' if no name is available
  const handletool = () => {
    // console.log('goto next tools page ');
    history.push(`/user-panel/tools`);
  };
  return (
    <Container>
      <ContentWrapper>
        <TextContainer>
          <Welcome>{`Hey, ${displayFullName}`}</Welcome> {/* Dynamically display user's name */}
          <Description>Welcome to Dinabite</Description>
          <Description>
            Streamline your social media in one place, share across<br /> platforms with
            ease, and let AI handle the heavy lifting.
          </Description>
          <Description>
            Connect now to discover a simpler way to manage your <br />online presence.
          </Description>
          <ButtonWrapper>
            <Button onClick={handletool}>Connect your account</Button>
          </ButtonWrapper>
        </TextContainer>
        <ImageContainer>
          <img src={HashtagImage} alt="Social Media Hashtag" />
        </ImageContainer>
      </ContentWrapper>
    </Container>
  );
};

export default Noconnection;

import  { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader, FlexboxGrid } from 'rsuite';

import {
  StepHeaderContainer,
  CreateAccountTitle,
  StepIndicator,
  StepCircle,
  MobileImage,
  MobileImageContent,
  CancelButton,
} from './dinabitesignup.style';
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
} from '../DinabiteSignupPanel/globaldinabite.style';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import welcomeImage from '../../assets/images/Illustration (2).png';
import welcomeImage2 from '../../assets/images/5492288.png';
import toolsimage from '../../assets/images/333.png';
import well from '../../assets/images/wellcome.png';
import well2 from '../../assets/images/naee.png';
import { getTitleForStep, getTextForStep } from '../steputils/steputils';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';
import { createUserSocial, createCompany } from '../../services/createUser';
import {
  INITIAL_VALUES_PROVIDER,
  LOGIN_PROVIDER_KEY,
} from '../../lib/globalValues';
import {
  notificationError,
} from '../../lib/notification';
import { sendverifyingEmail } from '../../services/sendverifyingemail';
import Step0 from './step0';
import Modal from '../DinabiteSignin/modal';

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
  address?: string;
  street?: string;
  postcode?: string;
  city?: string;
  zipCode?: string; // New field
  phone?: string; // New field
}

interface SignupLocationState {
  fromLogin: boolean;
  currentStep2?: number;
  email: string;
  password?: string; // Make this optional if it's not always provided
}
interface SignupLocationState {
  activationform: boolean; // Indicates if this navigation is from account activation
  currentStep2?: number; // Optional, can be used to set the current step in the form
}

interface CreateCompanyRequest {
  email: string; // Required
  name: string; // Required
  country: string; // Required
  address: string; // Required
  city: string; // Required
  zipCode: string; // Required
  phone: string; // Required
  phoneCode: string; // Required
  website: string;
}

const DinabiteLogin = () => {
  const { loginSocial } = useUser();
  const history = useHistory();
  const {  loginProvider, setLoginProvider } =
    useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const {  userID, tokenID } = loginProvider;
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const location = useLocation<SignupLocationState>();
  const [emaillogin, setEmaillogin] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState(''); // State for modal email
  const [isLoadingg, setIsLoadingg] = useState(false);


  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    name: '',
    accountType: 'EMAIL',
    address: '',
    street: '',
    postcode: '',
    phoneCode: '',
    phonecode: '',
    city: '',
    agreeToTerms: false,
    website: '',
  });
  const initialFormValues = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    name: '',
    accountType: 'EMAIL',
    address: '',
    street: '',
    postcode: '',
    phoneCode: '',
    phonecode: '',
    city: '',
    agreeToTerms: false,
    website: '',
  };
  const resetForm = () => {
    setFormValues(initialFormValues); // Reset to initial values
  };
  useEffect(() => {
    if (loginProvider?.data) {
      const userEmail = loginProvider.data.email;

      if (userEmail) {
        const stepFromURL = parseInt(
          new URLSearchParams(window.location.search).get('step') || '2',
          10,
        );
        setCurrentStep(stepFromURL || 2);
      }
    } else {
      const loginProviderKey = localStorage.getItem(LOGIN_PROVIDER_KEY);
      localStorage.removeItem(LOGIN_PROVIDER_KEY);
      if (loginProviderKey) {
        try {
          const retrievedLoginProvider = JSON.parse(loginProviderKey);
          setLoginProvider(retrievedLoginProvider);
        } catch (error) {
          // Handle JSON parse error
        }
      } else {
        // console.log('No loginProviderKey found in localStorage.');
      }
    }
  }, [loginProvider, setLoginProvider]);


  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      const { email } = parsedUser;

      if (email) {
        setEmaillogin(email);
      } else {
        setCurrentStep(0);
      }
    } else {
      // setCurrentStep(0);
    }
    if (location.state) {
      // If activationform is true and email exists in state
      if (location.state.activationform && location.state.email) {
        setEmaillogin(location.state.email || '');
        setCurrentStep(
          location.state.currentStep2 !== undefined
            ? location.state.currentStep2
            : 0,
        );
      }
      // If fromLogin is true but activationform is not present
      else if (location.state.fromLogin) {
        setEmaillogin(location.state.email || '');
        setCurrentStep(
          location.state.currentStep2 !== undefined
            ? location.state.currentStep2
            : 0,
        );


      }
    }
  }, [location]);

  const handleCountryChange = useCallback((country: string) => {
    setSelectedCountry(country);
  }, []);

  const handleNextStep = async (values: FormValues) => {
    const latestValues = { ...formValues, ...values };
    setFormValues(latestValues);
    if (currentStep === 0) {
      setCurrentStep(1);
      return;
    }

    if (currentStep === 1) {
      setIsLoadingg(true);
      // setIsLoading(false); // Stop loading

      const {
        email,
        password,
        firstName = '', 
        lastName = '', 
        companyName = '', 
        country = '', 
      } = latestValues;

      const updatedValues = {
        email,
        password,
        firstName,
        lastName,
        name: latestValues.name || companyName || 'name', 
        country: selectedCountry || 'United Kingdom', 
        phoneCode:latestValues.phonecode||'+44',
        accountType: 'email',
      };
      // console.log("apoooooooooooo phonecode",latestValues.phonecode)
      // console.log("apoooooooooooo phoneCode",updatedValues.phoneCode)
      try {
        const response2 = await createUserSocial(updatedValues, () => {
          // Success callback - Optional: Add notifications here if needed
        });

        // Handle the response as needed
        if (response2.data) {
          // setIsLoadingg(true);
          setShowModal(true);
          setModalEmail(email); // Set email for modal
          setModalMessage(
            'For activation of your account, a verification email has been sent to you.',
          );
          // Optional: Send verification email if needed
          const emailResponse = await sendverifyingEmail(email);
          if (emailResponse?.data) {
            // Show success notification for email sent
            // notification({
            //   type: 'success',
            //   title: 'Email Sent',
            //   description:
            //     'A verification email has been sent. Please check your email.',
            // });
            resetForm();
            setIsLoadingg(false);
          } else {
            // Handle failure to send verification email
            notificationError({
              title: 'Error',
              description:
                'There was a problem sending the verification email.',
            });
            resetForm();
            setIsLoadingg(false);
          }
        } else {
          notificationError({
            title: 'Error',
            description: 'Failed to create user account. Please try again.',
          });
          setIsLoadingg(false);
        }
      } catch (error) {
        notificationError({
          title: 'Error',
          description: 'An unexpected error occurred. Please try again.',
        });
        setIsLoadingg(false);
      } finally {
        // setIsLoading(false); // Stop loading
        // setIsLoadingg(false);
      }
      setIsLoadingg(false);

      return;
    }

    if (currentStep === 2) {
      const { socialProvider } = loginProvider;
      if (socialProvider === 'google' || socialProvider === 'facebook') {
        if (loginProvider && loginProvider.data) {
          const {
            name,
          } = latestValues;
          const updatedValues = {
            ...latestValues,
            companyName: name,
            country: latestValues.country || 'United Kingdom', // Default to 'United States'

          };
          // console.log("updated values for creating user",updatedValues)
          try {
            const response = await createUserSocial(updatedValues, () => {
              // notificationWithNodes({
              // });
            });
            const safeValues: CreateCompanyRequest = {
              email: updatedValues.email || emaillogin || 'default@example.com', // Fallback to a default email if null, undefined, or empty
              name: updatedValues.name || latestValues.companyName || 'name', // Fallback to 'name'
              country: updatedValues.country || 'United Kingdom', // Default to 'United States'
              address: updatedValues.address || '123 Default St', // Default to 'United States'
              city: updatedValues.city || 'Washington Dc', // Default to 'Washington Dc'
              zipCode: updatedValues.postcode || '00000', // Default to '00000'
              phone: updatedValues.phone || '000-000-0000', // Default to '000-000-0000'
              phoneCode: latestValues.phonecode || '+44',
              website: updatedValues.website || 'website',
            };

            // console.log("updated values are",safeValues)
            // Create the company
            const response2 = await createCompany(safeValues);
            if (response2) {
              // console.log("")
              // just put the if conndition to remove upper api eslit issue
            }
            if (response.data) {
              if (!values.password) {
                if (userID) {
                  await loginSocial(tokenID, 'facebook', userID);
                } else {
                  await loginSocial(tokenID, 'google');
                }
              }
              setCurrentStep(3);
              // }
            }
          } catch (error) {
            // Handle error (e.g., show an error notification)
          } finally {
            // setIsLoading(false);
          }
        }
      } else {
        try {
          const safeValues: CreateCompanyRequest = {
            email: emaillogin,
            name: latestValues.name || latestValues.companyName || 'name',
            country: latestValues.country || 'United Kingdom',
            address: latestValues.address || 'United Kingdom',
            city: latestValues.city || ' Dc',
            zipCode: latestValues.postcode || '00000',
            phone: latestValues.phone || '000-000-0000',
            phoneCode: latestValues.phonecode || '+44',
            website: latestValues.website || 'website',
          };

          // console.log("updated with email values are",safeValues)
          const response2 = await createCompany(safeValues);
          // open after check please
          if (response2) {
            setCurrentStep(3);
          } else {
            // console.error('Login failed after signup');
          }
        } finally {
          //
        }
      }
      return;
    }

    // if (currentStep === 3) {
    //   console.log("email is",emaillogin)
    // }
    if (currentStep === 4) {
      setLoginProvider(INITIAL_VALUES_PROVIDER);
      history.push('/user-panel/dashboard');
    }
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCancel = () => {
    setLoginProvider({
      tokenID: '',
      provider: '',
      userID: '',
      socialProvider: '',
      data: {
        email: '',
        name: '',
        socialId: '',
        register: false,
      },
    });
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    setCurrentStep(0);
    history.push('/');
  };

  return (
    <>
      <Container>
        <LeftPane>
          <StepHeaderContainer>
            <CreateAccountTitle
              style={{ display: currentStep === 0 ? 'none' : 'flex' }}>
              {getTitleForStep(currentStep)}
            </CreateAccountTitle>
            <StepIndicator
              style={{ display: currentStep === 0 ? 'none' : 'flex' }}>
              {[1, 2, 3, 4].map(step => (
                <StepCircle key={step} active={currentStep === step} />
              ))}
            </StepIndicator>

            {(currentStep === 1 || currentStep === 2) && (
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            )}
          </StepHeaderContainer>
          <MobileImage step={currentStep}>
            {currentStep === 0 && (
              <MobileImageContent src={welcomeImage} alt="Step 0 Image" />
            )}
            {currentStep === 1 && (
              <MobileImageContent src={welcomeImage} alt="Step 1 Image" />
            )}
            {currentStep === 2 && (
              <MobileImageContent src={welcomeImage2} alt="Step 2 Image" />
            )}
            {currentStep === 3 && (
              <MobileImageContent src={well2} alt="Step 3 Image" />
            )}
            {currentStep === 4 && (
              <MobileImageContent src={well} alt="Step 4 Image" />
            )}
          </MobileImage>
          {currentStep === 0 && (
            <Step0
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 1 && (
            <Step1
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              initialValues={formValues}
            />
          )}
          {currentStep === 2 && (
            <Step2
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              initialValues={formValues}
              loginProvider={loginProvider}
            />
          )}
          {currentStep === 3 && (
            <Step3
              onNext={() => handleNextStep(formValues)}

            />
          )}
          {currentStep === 4 && <Step4 />}
        </LeftPane>

        <RightPane>
          <ImageContainer>
            {currentStep === 0 && (
              <StyledImage src={welcomeImage} alt="Illustration" />
            )}
            {currentStep === 1 && (
              <StyledImage src={welcomeImage} alt="Illustration" />
            )}
            {currentStep === 2 && (
              <StyledImage src={welcomeImage2} alt="Illustration" />
            )}
            {currentStep === 3 && (
              <StyledImage src={toolsimage} alt="Illustration" />
            )}
            {currentStep === 4 && <StyledImage src={well} alt="Illustration" />}
            <DinabiteText>{getTextForStep(currentStep)}</DinabiteText>
          </ImageContainer>
        </RightPane>
      </Container>
      {isLoadingg && (
        <FlexboxGrid
          justify="center"
          align="middle"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}>
          <FlexboxGrid.Item>
            <Loader size="lg" content="Signing Up..." />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
      {showModal && (
        <Modal
          message={modalMessage}
          email={modalEmail}
          onClose={handleCloseModal}
          showModal={showModal}
          isLoginPage={false}
        />
      )}
    </>
  );
};

export default DinabiteLogin;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, FlexboxGrid, Row } from 'rsuite';
import axios from '../../lib/axios';
import Countries from '../../assets/data/countries.json';
import AdressIcon from '../../assets/icons/address.svg';
import CityIcon from '../../assets/icons/city.svg';
import EmailIcon from '../../assets/icons/email.svg';
import KeyIcon from '../../assets/icons/key.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import SaveIcon from '../../assets/icons/save.svg';
import UserIcon from '../../assets/icons/user.svg';
import WebIcon from '../../assets/icons/web.svg';
import IconInput from '../../components/inputs/IconInput/IconInput';
import { useAuth } from '../../context/AuthContext';
import { formatDate } from '../../lib/format';
import { changePassword } from '../../services/changePassword';
import { updateCompany } from '../../services/updateCompany';
import { updateUser } from '../../services/updateUser';
import {
  BillingContainer,
  Card,
  ColSelect,
  Container,
  GroupSelect,
  HeaderContainer,
  IconSelect,
  PError,
  PlanContainer,
  SaveButton,
  SelectStyle,
} from './Account.styles';
import {
  billingEmailSchema,
  changePasswordSchema,
  userCompanySchema,
  userInfoSchema,
} from './validation';
import RectangleButton from '../../components/rectangleButton/RectangleButton';
import PremiumPlan from '../premiumPlan/PremiumPlan';
import BasicPlan from '../basicPlan/BasicPlan';
import { updateStripeCustomer } from '../../services/updateStripeCustomer';
import { notificationError } from '../../lib/notification';

const Account = () => {
  const { user, setUser, companySelected } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);
  const [billingEmail, setBillingEmail] = useState<string>('');
  const [lastUpdateTime, setLastUpdateTime] = useState<string | null>(null);

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};

    if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  useEffect(() => {
    const getPayment = async () => {
      if (companySelected) {
        const response = await axios.get(
          `stripe/customer?companyId=${companySelected?.id}`,
        );

        setBillingEmail(response.data.email);
      }
    };
    getPayment();
  }, [companySelected, billingEmail]);

  const userInfoFormik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      id: user?.id || '',
      email: user?.email || '',
    },
    onSubmit: async values => {
      const data = { ...values };
      const { id, ...rest } = data;

      const updatedUser = await updateUser(+id, rest);
      if (updatedUser.data) {
        setUser({ ...data, companies: user?.companies });
        setLastUpdateTime(formatDate(new Date())); // Update the last update time
      }
    },
    enableReinitialize: true,
    validationSchema: userInfoSchema(user?.email),
  });

  const userBillingInfoFormik = useFormik({
    initialValues: {
      billingEmail: billingEmail || '',
    },
    onSubmit: async values => {
      const data = { ...values };

      const updatedStripeCustomer = await updateStripeCustomer(
        companySelected?.id,
        data.billingEmail,
      );

      if (updatedStripeCustomer.data) {
        setBillingEmail(updatedStripeCustomer.data?.billingEmail);
      }
    },
    enableReinitialize: true,
    validationSchema: billingEmailSchema,
  });

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      repeatPassword: '',
    },
    onSubmit: async values => {
      const changedPassword = await changePassword(values);
      if (changedPassword.data) {
        changePasswordFormik.resetForm();
      }
    },
    validationSchema: changePasswordSchema,
  });

  const companyInfoFormik = useFormik({
    initialValues: {
      id: user?.companies[0].id || 0,
      name: user?.companies[0].name || '',
      phone: user?.companies[0].phone || '',
      phoneCode: user?.companies[0].phoneCode || '',
      address: user?.companies[0].address || '',
      country: user?.companies[0].country || '',
      city: user?.companies[0].city || '',
      zipCode: user?.companies[0].zipCode || '',
      webLink: user?.companies[0].webLink || '',
    },
    onSubmit: async (values: {
      id: number;
      name: string;
      phone: string;
      phoneCode: string;
      address: string;
      country: string;
      city: string;
      zipCode: string;
      webLink: string;
    }) => {
      if (user) {
        const { id, ...data } = values;
        const updatedCompany = await updateCompany(id, data);
        if (updatedCompany.data) {
          const selectUpdated = user.companies.filter(
            company => company.id !== id,
          );

          setUser({
            ...user,
            companies: [...selectUpdated, values],
          });
          setLastUpdateTime(formatDate(new Date())); // Update the last update time
        }
      }
    },
    enableReinitialize: true,
    validationSchema: userCompanySchema,
  });

  const handleSave = () => {
    if (userInfoFormik.initialValues !== userInfoFormik.values) {
      userInfoFormik.submitForm();
    }

    if (companyInfoFormik.initialValues !== companyInfoFormik.values) {
      companyInfoFormik.submitForm();
    }

    if (userBillingInfoFormik.initialValues !== userBillingInfoFormik.values) {
      userBillingInfoFormik.submitForm();
    }

    if (changePasswordFormik.values.password.length > 0) {
      changePasswordFormik.submitForm();
    }
  };

  const handleManageBillingSubmit = async () => {
    try {
      if (companySelected?.id) {
        const ManageBillingURL = await axios.post(
          `stripe/create-customer-portal-session`,
          { companyId: companySelected.id },
        );

        window.location.href = ManageBillingURL.data;
      }
    } catch (err) {
      notificationError({
        title: 'Error',
        description: 'Error',
      });
    }
  };

  return (
    <Container>
      <BillingContainer>
        <Col xs={24} md={24}>
          <h2>Plans & Billing</h2>
        </Col>
        {displayPremiumPlan && (
          <Col xs={24}>
            <RectangleButton
              title="Manage Billing"
              onClick={handleManageBillingSubmit}
            />
          </Col>
        )}
        <Col xs={24} md={12} style={{ marginTop: 20 }}>
          <PlanContainer>
            <BasicPlan />
          </PlanContainer>
        </Col>
        <Col xs={24} md={12} style={{ marginTop: 20 }}>
          <PlanContainer>
            <PremiumPlan />
          </PlanContainer>
        </Col>
      </BillingContainer>
      <HeaderContainer justify="space-between">
        <h1>{`${user?.firstName} ${user?.lastName}`}</h1>
        <div>
          <SaveButton onClick={handleSave}>
            Save Changes
            <img src={SaveIcon} alt="s" style={{ width: 16, marginLeft: 20 }} />
          </SaveButton>
          <span>
            Last Update:{' '}
            {lastUpdateTime || (user?.updatedAt && formatDate(user.updatedAt))}
          </span>
        </div>
      </HeaderContainer>
      <Card>
        <h2>Edit profile</h2>
        <h1>Account Details</h1>
        <form>
          <FlexboxGrid>
            <Col xs={24} md={12}>
              <IconInput
                icon={UserIcon}
                iconAlt="User Icon"
                inputType="text"
                value={userInfoFormik.values.firstName}
                onChange={userInfoFormik.handleChange('firstName')}
                placeholder="First name"
                error={userInfoFormik.errors.firstName}
              />
            </Col>
            <Col xs={24} md={12}>
              <IconInput
                icon={UserIcon}
                iconAlt="User Icon"
                inputType="text"
                value={userInfoFormik.values.lastName}
                onChange={userInfoFormik.handleChange('lastName')}
                placeholder="Last Name"
                error={userInfoFormik.errors.lastName}
              />
            </Col>

            <Col xs={24} md={12}>
              <IconInput
                icon={EmailIcon}
                iconAlt="Address Icon"
                inputType="email"
                value={userInfoFormik.values.email}
                onChange={userInfoFormik.handleChange('email')}
                placeholder="Email"
                error={userInfoFormik.errors.email}
                disabled
              />
            </Col>
            <Col xs={24} md={12}>
              <IconInput
                icon={EmailIcon}
                iconAlt="Address Icon"
                inputType="email"
                value={userBillingInfoFormik.values.billingEmail}
                onChange={userBillingInfoFormik.handleChange('billingEmail')}
                placeholder="Billing Email"
                error={userBillingInfoFormik.errors.billingEmail}
              />
            </Col>
          </FlexboxGrid>
        </form>
      </Card>

      <Card>
        <Row>
          <Col xs={24} md={12}>
            <h2>Edit business </h2>
          </Col>
        </Row>
        <h1>Business Details</h1>
        <form>
          <FlexboxGrid>
            <Col xs={24} md={8}>
              <IconInput
                icon={UserIcon}
                iconAlt="User Icon"
                inputType="text"
                value={companyInfoFormik.values.name}
                onChange={companyInfoFormik.handleChange('name')}
                placeholder="Business name*"
                error={companyInfoFormik.errors.name}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={AdressIcon}
                iconAlt="Adress Icon"
                inputType="text"
                value={companyInfoFormik.values.address}
                onChange={companyInfoFormik.handleChange('address')}
                placeholder="Address"
                error={companyInfoFormik.errors.address}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={CityIcon}
                iconAlt="City Icon"
                inputType="text"
                value={companyInfoFormik.values.city}
                onChange={companyInfoFormik.handleChange('city')}
                placeholder="City"
                error={companyInfoFormik.errors.city}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={AdressIcon}
                iconAlt="Post Code Icon"
                inputType="text"
                value={companyInfoFormik.values.zipCode}
                onChange={companyInfoFormik.handleChange('zipCode')}
                placeholder="Post code"
                error={companyInfoFormik.errors.zipCode}
              />
            </Col>

            <ColSelect xs={24} md={8}>
              <GroupSelect>
                <IconSelect>
                  <img src={WebIcon} alt="country icon" />
                </IconSelect>
                <SelectStyle
                  options={Countries}
                  placeholder={
                    <span>
                      {companyInfoFormik.initialValues.country === '' ? (
                        'Country*'
                      ) : (
                        <span style={{ color: 'black' }}>
                          {companyInfoFormik.initialValues.country}
                        </span>
                      )}
                    </span>
                  }
                  onChange={(option: any) => {
                    companyInfoFormik.setFieldValue(
                      'country',
                      option?.value,
                      true,
                    );
                    companyInfoFormik.setFieldValue(
                      'phoneCode',
                      option?.phoneCode,
                      true,
                    );
                  }}
                />
              </GroupSelect>
              <PError>{companyInfoFormik.errors.country}</PError>
            </ColSelect>

            <Col xs={24} md={8}>
              <IconInput
                icon={PhoneIcon}
                iconAlt="Country code Icon"
                inputType="text"
                value={companyInfoFormik.values.phoneCode}
                readOnly
                onChange={companyInfoFormik.handleChange('phoneCode')}
                placeholder="Country code*"
                error={companyInfoFormik.errors.phoneCode}
              />
            </Col>
            <Col xs={24} md={8}>
              <IconInput
                icon={PhoneIcon}
                iconAlt="Phone Icon"
                inputType="text"
                value={companyInfoFormik.values.phone}
                onChange={companyInfoFormik.handleChange('phone')}
                placeholder="Phone"
                error={companyInfoFormik.errors.phone}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={WebIcon}
                iconAlt="Website Icon"
                inputType="text"
                value={companyInfoFormik.values.webLink}
                onChange={companyInfoFormik.handleChange('webLink')}
                placeholder="Website"
                error={companyInfoFormik.errors.webLink}
              />
            </Col>
          </FlexboxGrid>
        </form>
      </Card>
      <HeaderContainer justify="end">
        <div>
          <SaveButton onClick={handleSave}>
            Save Changes
            <img src={SaveIcon} alt="s" style={{ width: 16, marginLeft: 20 }} />
          </SaveButton>
          <span>
            Last Update:{' '}
            {lastUpdateTime || (user?.updatedAt && formatDate(user.updatedAt))}
          </span>
        </div>
      </HeaderContainer>
      <Card>
        <h2>Security</h2>
        <h1>Password & Security</h1>
        <form>
          <Row>
            <Col xs={24} md={8}>
              <IconInput
                icon={KeyIcon}
                iconAlt="Key Icon"
                inputType="password"
                value={changePasswordFormik.values.oldPassword}
                onChange={changePasswordFormik.handleChange('oldPassword')}
                placeholder="Old Password"
                error={changePasswordFormik.errors.oldPassword}
              />
            </Col>
            <Col xs={24} md={8}>
              <IconInput
                icon={KeyIcon}
                iconAlt="Key Icon"
                inputType="password"
                value={changePasswordFormik.values.password}
                onChange={changePasswordFormik.handleChange('password')}
                placeholder="New Password"
                error={changePasswordFormik.errors.password}
              />
            </Col>
            <Col xs={24} md={8}>
              <IconInput
                icon={KeyIcon}
                iconAlt="Key Icon"
                inputType="password"
                value={changePasswordFormik.values.repeatPassword}
                onChange={changePasswordFormik.handleChange('repeatPassword')}
                placeholder="Repeat Password"
                error={changePasswordFormik.errors.repeatPassword}
              />
            </Col>
          </Row>
        </form>
      </Card>
    </Container>
  );
};

export default Account;
